import React from 'react'
import { List, ListItem, Paper, Stack, Typography } from '@mui/material'
import { ActionMission, DialogMission } from '@/services/three/worldContainer/core/quests/type/typeQuest'

const Index = () => {


  return (
    <Paper style={{
      color: 'white',
      position: 'fixed',
      top: '20%',
      right: 0,
      width: '20%',
      maxHeight: '40%',
      backgroundColor: 'rgba(0,0,0,0.7)',
    }}>
      <Stack direction='column' gap='20px'>
          <Stack direction='row' justifyContent='space-around'>
              <Typography color='white' fontWeight='600'>
                SPACE
              </Typography>
            <Typography color='white' fontWeight='400'>
               Запустить игру
            </Typography>
          </Stack>
      </Stack>
    </Paper>
  )
}


export default Index
