import { KeyEvent } from '../../common/controller/controller.model'
import { EventStatus, IVector2 } from '../../common/event.model'
import 'reflect-metadata'
import { fromEvent, merge, Observable, Subject } from 'rxjs'
import { distinctUntilChanged, map, tap } from 'rxjs/operators'

class MainControllerComponent {
  readonly resize$: Observable<IVector2>
  readonly key$: Observable<KeyEvent>
  readonly pointerLockSubject = new Subject<EventStatus>()
  readonly pointerLock$ = this.pointerLockSubject.pipe()
  private readonly resizeObject: IVector2 = { x: 0, y: 0 }
  private readonly mouseMoveObject: IVector2 = { x: 0, y: 0 }
  private readonly mouseMoveSubject = new Subject<IVector2>()
  readonly mouseMove$ = this.mouseMoveSubject.pipe()

  // private readonly mouseMovePointerObject: any = {}
  private readonly mouseMovePointerSubject = new Subject<PointerEvent>()
  readonly mouseMovePointer$ = this.mouseMovePointerSubject.pipe()

  constructor() {
    this.resize$ = fromEvent(window, 'resize').pipe(
      tap(() => {
        this.resizeObject.x = window.innerWidth
        this.resizeObject.y = window.innerHeight
      }),
      map(() => this.resizeObject),
    )

    this.key$ = merge(
      (fromEvent(window, 'keydown') as Observable<KeyboardEvent>).pipe(
        map((event) => ({
          status: EventStatus.ON,
          key: event.code,
        })),
      ),
      (fromEvent(window, 'keyup') as Observable<KeyboardEvent>).pipe(
        map((event) => ({
          status: EventStatus.OFF,
          key: event.code,
        })),
      ),
    ).pipe(
      distinctUntilChanged((prev, curr) => {
        return prev.key === curr.key && prev.status === curr.status
      }),
    )
  }

  init(canvas: HTMLElement): void {
    (fromEvent(canvas, 'mousemove') as Observable<MouseEvent>)
      .pipe(
        tap((event) => {
          if (document.pointerLockElement) {
            this.mouseMoveObject.x = event.movementX
            this.mouseMoveObject.y = event.movementY
          } else {
            this.mouseMoveObject.x = (event.clientX / window.innerWidth) * 2 - 1
            this.mouseMoveObject.y = -(event.clientY / window.innerHeight) * 2 + 1
          }
        }),
        map(() => this.mouseMoveObject),
      )
      .subscribe((object) => this.mouseMoveSubject.next(object))

    fromEvent(document, 'pointerlockchange')
      .pipe(
        map(
          () =>
            document.pointerLockElement === canvas ||
            // @ts-expect-error
            document.mozPointerLockElement === canvas,
        ),
        map((locked) => (locked ? EventStatus.ON : EventStatus.OFF)),
      )
      .subscribe((status) => this.pointerLockSubject.next(status))

    fromEvent(canvas, 'dblclick').subscribe(() => canvas.requestPointerLock())
  }
}

export { MainControllerComponent }
