import React, { useMemo } from 'react'
// @ts-expect-error
import { ReactComponent as ClickIcon } from '@/assets/svgs/ClickIcon.svg'
import { Stack, Typography } from '@mui/material'
import { useAppSelector } from '@/hooks/redux'
import { selectHelperData } from '@/store/selectors/three'


const Index = () => {
  const { type, text } = useAppSelector(selectHelperData)
  const svg = useMemo(() => {

    switch (type) {
      case 1:
        return <ClickIcon />
      case 0:
        return <ClickIcon />
    }
  }, [type])

  return (
    <Stack
      display='flex'
      direction='row'
      gap='13px'
      bgcolor='var(--opacity-dark-40, rgba(32, 32, 40, 0.40))'
      justifyContent='center'
      alignItems='center'
      padding='16px 24px'
      borderRadius='100px'
    >
      <Stack display='flex'>
        {svg}
      </Stack>
      <Stack display='flex'>
        <Typography fontWeight='600' className='unselectable' fontSize='18px' color='var(--common-white, #FFF)'>
          {text}
        </Typography>
      </Stack>
    </Stack>
  )
}

export default Index
