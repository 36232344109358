// @ts-nocheck
import { spawn, Worker } from 'threads'
import { MainControllerComponent } from '@/services/three/worldContainer/main/controller/main-controller.component'
import { GuiManager } from '@/services/three/worldContainer/main/APIMiddlewareWorker/gui.manager'
import { CoreThread } from '@/services/three/worldContainer/core/worker'
import 'reflect-metadata'
import {
  CharacterInterface,
  NftInterface,
  SocketUserDataInterface,
  SpaceObjectsInterface,
  UpdateCharacterInterface,
  UserWithBalanceInterface,
} from '@/types/types'
import { WorkerImplementation, WorkerMessageEvent } from 'threads/dist/types/master'
import { APIWorkerComponent } from '@/services/three/worldContainer/main/APIMiddlewareWorker/APIWorkerComponent'
import { Quest } from '@/services/three/worldContainer/core/quests/type/typeQuest'
import ActiveQuest from '@/services/three/worldContainer/core/quests/activeQuest'
import OrbitControlsProxy from '@/services/three/worldContainer/fictional/OrbitControlsProxy'


export interface DataWorldInterface {
  mode: string
  userId: string
  pathScene: string
  nfts: NftInterface[]
  objects: SpaceObjectsInterface[]
  character: CharacterInterface
  quest: Quest
}

export class WorldContainer {
  data: any
  firstVideo = false
  secondVideo = false
  private readonly canvas: HTMLCanvasElement = document.createElement('canvas')
  private coreThread?: CoreThread
  private readonly controller: MainControllerComponent = new MainControllerComponent()
  private readonly users: SocketUserDataInterface[] = []
  private readonly apiWorkerComponent: APIWorkerComponent
  private coreWorker?: WorkerImplementation
  private interval1: NodeJS.Timer
  private interval2: NodeJS.Timer

  constructor(
    mode: 'dev' | 'create' | 'view',
    character: UserWithBalanceInterface,
    private readonly pointerDown: (e: PointerEvent) => void,
    private readonly pointerUp: (e: PointerEvent) => void,
    private readonly gamePlay: (e: PointerEvent) => void,
    quest?: ActiveQuest,
  ) {
    this.apiWorkerComponent = new APIWorkerComponent()
    new GuiManager(this.apiWorkerComponent, this.controller)
    this.data = { mode, quest: quest?.quest, character }
    this.initCanvas()
    this.initCoreThread()
    console.log('Main thread OK')
    this.sendVideo2 = this.sendVideo2.bind(this)
    this.sendVideo1 = this.sendVideo1.bind(this)
    this.sendVideo = this.sendVideo.bind(this)
  }

  addCharacter(id: string, user: SocketUserDataInterface) {
    if (this.coreThread) {
      this.coreThread?.addCharacter(id, user)
    } else {
      this.users.push(user)
    }
  }

  updateCharacter(data: UpdateCharacterInterface) {
    this.coreThread?.updateCharacter(data)
  }

  toggleCameraMode(v: boolean) {
    this.coreThread?.toggleCameraMode(v)
  }

  toggleMovedMode(v: boolean) {
    this.coreThread?.toggleMovedMode(v)
  }

  deleteCharacter(id: string) {
    this.coreThread?.deleteCharacter(id)
  }

  updateObjects(newObjects: SpaceObjectsInterface[]) {
    this.coreThread?.updateObjects(newObjects)
  }

  addObject(
    creator: boolean,
    object: SpaceObjectsInterface,
    url: string,
    onLoad: () => void,
  ) {
    this.coreThread?.addObject(creator, object, url)
    onLoad()
  }

  setDataQuest(v: Quest) {
    this.coreThread?.setDataQuest(v)
  }

  setSlotAsset(slotId: number, nft: NftInterface | null) {
    this.coreThread?.setSlotAsset(slotId, nft)
  }

  updateMap(data: { pathScene: string, objects: [] }) {
    this.coreThread?.updateMap(data)
  }

  disposeCore() {
    void this.coreWorker?.terminate()
  }

  // sendVideo() {
  //   setInterval(() => {
  //     imageCapture.grabFrame().then(imageBitmap => {
  //       this.coreWorker.postMessage({ imageBitmap, type: 'video' }, [imageBitmap])
  //     })
  //   }, 200)
  // }
  //
  // checkVideo(imageCapture) {
  //   setTimeout(() => {
  //     imageCapture.grabFrame().then(imageBitmap => {
  //       this.coreWorker.postMessage({ imageBitmap, type: 'video' }, [imageBitmap])
  //       this.sendVideo()
  //     }).catch(error => {
  //       this.checkVideo(imageCapture)
  //     })
  //   }, 500)
  // }
  //
  // updateVideo() {
  //   const video: HTMLVideoElement = document.getElementById('mainPlay')
  //   this.video = video
  //   const stream = video.captureStream()
  //   const [track] = stream.getVideoTracks()
  //   const imageCapture = new ImageCapture(track)
  //   if (this.firstVideo) {
  //
  //
  //   } else {
  //     imageCapture.grabFrame().then(imageBitmap => {
  //       this.coreWorker.postMessage({ imageBitmap, type: 'video' }, [imageBitmap])
  //     }).catch(error => {
  //       this.checkVideo(imageCapture)
  //     })
  //   }
  //
  //   if (this.secondVideo) {
  //     const video2: HTMLVideoElement = document.getElementById('generalPlay')
  //     const stream2 = video2.captureStream()
  //     const [track2] = stream2.getVideoTracks()
  //     const imageCapture2 = new ImageCapture(track2)
  //     imageCapture2.grabFrame().then(imageBitmap => {
  //       this.coreWorker.postMessage({ imageBitmap, type: 'video2' }, [imageBitmap])
  //     }).catch(error => {
  //       setTimeout(() => {
  //         this.checkVideo(imageCapture2)
  //       }, 500)
  //     })
  //   }
  // }

  sendVideo1() {
    if (this.interval1) return
    const video: HTMLVideoElement = document.getElementById('mainPlay')
    const stream = video.captureStream()
    const [track] = stream.getVideoTracks()
    if (!track) {
      this.firstVideo = false
      return
    }
    if (this.secondVideo) {
      this.sendVideo()
    }
  }

  sendVideo2() {
    if (this.interval1) return
    const video2: HTMLVideoElement = document.getElementById('generalPlay')
    const stream2 = video2.captureStream()
    const [track2] = stream2.getVideoTracks()
    if (!track2) {
      this.secondVideo = false
      return
    }
    this.secondVideo = true
    const imageCapture2 = new ImageCapture(track2)
    if (this.firstVideo) {
      this.sendVideo()
    }
  }

  sendVideo() {
    console.log("АЛООО")
    const video: HTMLVideoElement = document.getElementById('mainPlay')
    const stream = video.captureStream()
    const [track] = stream.getVideoTracks()
    const imageCapture = new ImageCapture(track)

    const video2: HTMLVideoElement = document.getElementById('generalPlay')
    const stream2 = video2.captureStream()
    const [track2] = stream2.getVideoTracks()
    const imageCapture2 = new ImageCapture(track2)
    this.interval1 = setInterval(() => {
      imageCapture.grabFrame().then(imageBitmap => {
        this.coreWorker.postMessage({ imageBitmap, type: 'video' }, [imageBitmap])
      }).catch(error => this.firstVideo = false)
      imageCapture2.grabFrame().then(imageBitmap => {
        this.coreWorker.postMessage({ imageBitmap, type: 'video2' }, [imageBitmap])
      }).catch(error => this.firstVideo = false)
    }, 100)
  }

  private async initThread<T>(worker: WorkerImplementation): Promise<[T, WorkerImplementation]> {
    // @ts-expect-error
    const thread = ((await spawn<unknown>(worker)) as unknown) as T
    return [thread, worker]
  }

  private initCanvas(): void {
    this.canvas.width = window.innerWidth
    this.canvas.height = window.innerHeight
    this.canvas.id = 'scene_canvas'
    this.canvas.style.zIndex = 1000

    const root = document.getElementById('root')
    if (root) {
      root.appendChild(this.canvas)
    }
  }

  private initCoreThread(): void {
    const offscreenCanvas = this.canvas.transferControlToOffscreen()
    offscreenCanvas.width = this.canvas.clientWidth - 5
    offscreenCanvas.height = this.canvas.clientHeight - 3

    // @ts-expect-error
    void this.initThread<CoreThread>(new Worker(new URL('./../core/worker.ts', import.meta.url)))
      .then(([coreThread, coreWorker]) => {
        this.coreThread = coreThread
        this.coreWorker = coreWorker
        new OrbitControlsProxy(this.coreWorker, this.coreThread, document.body)
        this.initComponents()
        this.initCharacters()
        this.apiWorkerComponent.initCoreThread(this.coreThread)
        coreWorker.postMessage({
          id: 'scene_canvas',
          type: 'create_init',
          canvas: offscreenCanvas,
          data: this.data,
        }, [offscreenCanvas])


        // @ts-expect-error
        coreWorker.onmessage = (event: WorkerMessageEvent<any>) => {
          this.apiWorkerComponent.handleMessage(event)

          if (event.data?.type === 'downVideo') {
            const customEvent = {
              offsetX: 8 * event.data.data.relativeX * 1.4,
              offsetY: 600 - (6 * event.data.data.relativeY),
              currentTarget: { clientWidth: event.data.data.clientWidth, clientHeight: 600 },
            }

            this.pointerDown(customEvent)
          }

          if (event.data?.type === 'upVideo') {
            const customEvent = {
              offsetX: 8 * event.data.data.relativeX * 1.4,
              offsetY: 600 - (6 * event.data.data.relativeY),
              currentTarget: { clientWidth: event.data.data.clientWidth, clientHeight: 600 },
            }

            this.pointerUp(customEvent)
          }

          if (event.data?.type === 'Space') {
            this.gamePlay()
          }
        }
      })
  }

  private initCharacters(): void {
    this.users.forEach((user) => {
      this.coreThread?.addCharacter(user.id.toString(), user)
    })
  }

  private initComponents(): void {
    this.controller.init(this.canvas)
    this.controller.mouseMove$.subscribe((event) => this.coreThread?.mouseMove(event.x, event.y))
    this.controller.resize$.subscribe(() => this.coreThread?.setSize(window.innerWidth, window.innerHeight))
    this.controller.pointerLock$.subscribe((status) => {
      this.coreThread?.setPointerLock(status)
    })
    this.controller.key$.subscribe((keyEvent) => this.coreThread?.keyEvent(keyEvent))
  }
}
