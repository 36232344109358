import { SpacePortalInterface, UpdateCharacterInterface } from '@/types/types'
import { WorkerMessageEvent } from 'threads/dist/types/master'
import WorkerMessageType from '@/services/three/worldContainer/common/WorkerMessage.model'
import {
  SET_HELP_DATA,
  SET_LOADED_PERCENT, SET_LOADED_WORLD,
} from '@/store/slices/three'
import { AppDispatch, store } from '@/store'
import APIMessageType from '@/services/three/worldContainer/common/ApiMessage.model'
import { CoreThread } from '@/services/three/worldContainer/core/worker'

const dispatch: AppDispatch = store.dispatch

export class APIWorkerComponent {
  private readonly messageEventHandlers: Map<string, (data: any) => any>
  private readonly apiEventHandlers: Map<string, (data: any) => any>
  private coreThread?: CoreThread

  constructor(
  ) {
    this.messageEventHandlers = new Map([
      [WorkerMessageType.API, this.handleApiMessage.bind(this)],
      [WorkerMessageType.HELP, this.handleChangeHelpMessage.bind(this)],
      [WorkerMessageType.CURSOR, this.handleChangeCursor.bind(this)],
      [WorkerMessageType.TELEPORT, this.teleport.bind(this)],
      [WorkerMessageType.ON_POSITION_CHANGE, this.handlePositionCharactersChange.bind(this)],
      [WorkerMessageType.SELECTED_SLOT, this.handleSelectSlot.bind(this)],
      [WorkerMessageType.LOADING_FINISHED, this.handleLoading.bind(this)],
      [WorkerMessageType.SET_MODAL, this.handleChangeModal.bind(this)],
      [WorkerMessageType.GET_MINI_GAME, this.getMiniGame.bind(this)],
      [WorkerMessageType.GET_DATA_QUEST, this.getDataQuest.bind(this)],
      [WorkerMessageType.SET_LOADING_PERCENT, this.setLoadingPercent.bind(this)],
    ])

    this.apiEventHandlers = new Map([
      [APIMessageType.DELETE, this.handleDeleteObject.bind(this)],
      [APIMessageType.SAVE, this.handleSaveObject.bind(this)],
    ])
  }

  handleMessage(event: WorkerMessageEvent<any>) {
    const handler = this.messageEventHandlers.get(event.data.type)
    if (handler) {
      handler(event.data)
    }
  }


  getDataQuest(data: any) {
  }

  getMiniGame(data: any) {
    document.exitPointerLock()
  }

  handleChangeModal(data: any) {
  }

  initCoreThread(coreThread: CoreThread) {
    this.coreThread = coreThread
  }

  handleChangeHelpMessage(status: any): void {
    dispatch(SET_HELP_DATA(status.data))
  }

  teleport(portal: SpacePortalInterface): void {
  }

  getSpaceIdForTeleport = (portal: SpacePortalInterface) => {
  }

  setLoadingPercent(data: any) {
    dispatch(SET_LOADED_PERCENT(data.data.percent))
  }

  handleApiMessage(data: any) {
    const handler = this.apiEventHandlers.get(data.action)
    if (handler) {
      handler(data.data)
    }
    // const response = await ObjectsService.updateDraftObject(object.id, object.transform)
  }

  async handleDeleteObject(data: any) {
  }

  async handleSaveObject(data: any) {
  }

  handleLoading() {
    dispatch(SET_LOADED_WORLD(true))
  }

  handleChangeCursor(data: any) {
    document.body.style.cursor = data.data
  }

  handleSelectSlot(data: any) {
  }

  handlePositionCharactersChange(data: any) {
  }
}
