import React from 'react'
import './styles/App.css'
import { RouterProvider } from 'react-router-dom'
import MainLayout from '@/layouts/MainLayout'
import router from '@/router'
import { ThemeProvider } from '@mui/material'
import { store } from '@/store'
import { Provider } from 'react-redux'

const App: React.FC = () => {
  return (
    <Provider store={store}>
                <MainLayout />
    </Provider>
  )
}

export default App
