import { MainControllerComponent } from '../controller/main-controller.component'
import 'reflect-metadata'
import { APIWorkerComponent } from '@/services/three/worldContainer/main/APIMiddlewareWorker/APIWorkerComponent'

class GuiManager {
  constructor(
    private readonly component: APIWorkerComponent,
    private readonly controller: MainControllerComponent,
  ) {
    controller.pointerLock$.subscribe((status) => {
      if (status) {
        component.handleChangeHelpMessage({ data: { type: 1, text: 'Exiting 3d mode' }})
      } else {
        component.handleChangeHelpMessage({ data: { type: 0, text: 'Enter 3D mode *double-click' }})
      }
    })
  }
}

export { GuiManager }
