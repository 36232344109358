import { Box, CircularProgress, CircularProgressProps, Stack, Typography } from '@mui/material'
import React from 'react'
import { selectLoadedPercent } from '@/store/selectors/three'
import { useAppSelector } from '@/hooks/redux'



const Index = () => {
  const value = useAppSelector(selectLoadedPercent)
  return <Stack style={{transform: 'translate(-50%, -50%)' }} position='absolute' top='50%' left='50%'>
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress style={{ width: 200 }} variant='determinate' value={value} />
      <Box
        sx={{
          top: 0,
          bottom: 0,
          right: 0,
          left: '85%',
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography
          variant='caption'
          component='div'
          fontWeight='600'
          fontSize='36px'
          color='text.secondary'
        >{`${value}%`}</Typography>
      </Box>
    </Box>
  </Stack>
}

export default Index
