const WorkerMessageType = {
  API: 'api',
  HELP: 'help',
  CURSOR: 'cursor',
  TELEPORT: 'teleport',
  SELECTED_SLOT: 'selected_slot',
  ON_POSITION_CHANGE: 'on_position_change',
  LOADING_FINISHED: 'loading_finished',
  SET_MODAL: 'set_modal',
  GET_MINI_GAME: 'get_mini_game',
  GET_DATA_QUEST: 'get_data_quest',
  SET_LOADING_PERCENT: 'set_loading_percent'
}

export default WorkerMessageType
