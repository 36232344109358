import ControlsProxy from "./ControlsProxy"
import WorkerMessageType from "./WorkerMessageType"

const mouseEventProperties = ['type', 'pointerType', 'button', 'clientX', 'clientY', 'ctrlKey', 'metaKey', 'shiftKey', 'x', 'y']
const wheelEventProperties = ['type', 'deltaY']

/**
 * If your Three.js ^0.127.0
 * OrbitControls/TrackballControls: Replace event.keyCode with event.code
 * https://github.com/mrdoob/three.js/pull/21409
 */

const touchEventProperties = ['type']
const touchProperties = ['pageX', 'pageY']

/**
 * @description: OrbitControls Proxy
 */
class OrbitControlsProxy extends ControlsProxy {

    /**
     * @param worker
     * @param coreThread
     * @param {HTMLElement} htmlElement
     * @param {String} [elementID] default value: element
     * @return {void} void
     */
    constructor(worker, coreThread, htmlElement, elementID = 'element') {
        super(worker, coreThread, htmlElement, elementID)
        this.configEventListener()
    }

    handleResize = () => {

        const rect = this.htmlElement.getBoundingClientRect()
        const fictitiousEvent = {
            type: WorkerMessageType.RESIZE,
            id: this.elementID,
            top: rect.top,
            left: rect.left,
            width: rect.width,
            height: rect.height
        }
        this.worker.postMessage(fictitiousEvent)
    }

    /**
     * @param {PointerEvent} event
     * @return {void} void
     */
    handlePointerDown = (event) => {
        switch (event.pointerType) {

            case 'mouse':
            case 'pen':
                this.handleMouseDown(event);
                break;
            default:
                // TODO touch
                break

        }
    }

    /**
     * @param {PointerEvent} event
     * @return {void} void
     */
    handlePointerMove = (event) => {
        event.preventDefault();

        switch (event.pointerType) {

            case 'mouse':
            case 'pen':
                this.handleMouseMove(event);
                break;
            default:
                // TODO touch
                break
        }
    }

    /**
     * @param {PointerEvent} event
     * @return {void} void
     */
    handlePointerUp = (event) => {
        event.preventDefault();

        switch (event.pointerType) {

            case 'mouse':
            case 'pen':
                this.handleMouseUp(event);
                break;
            default:
                // TODO touch
                break

        }
    }

    /**
     * @param {MouseEvent} event
     * @return {void} void
     */
    handleMouseDown = (event) => {
        this.htmlElement.ownerDocument.addEventListener('pointermove', this.handlePointerMove);
        this.htmlElement.ownerDocument.addEventListener('pointerup', this.handlePointerUp);

        const fictitiousEvent = ControlsProxy.copyProperties(event, mouseEventProperties)
        this.coreThread.handlePointerDown(fictitiousEvent)
    }


    /**
     * @param {MouseEvent} event
     * @return {void} void
     */
    handleMouseMove = (event) => {
        event.preventDefault();

        const fictitiousEvent = ControlsProxy.copyProperties(event, mouseEventProperties)
        this.coreThread.handlePointerMove(fictitiousEvent)
    }

    /**
     * @param {MouseEvent} event
     * @return {void} void
     */
    handleMouseUp = (event) => {
        this.htmlElement.ownerDocument.removeEventListener('pointermove', this.handlePointerMove);
        this.htmlElement.ownerDocument.removeEventListener('pointerup', this.handlePointerUp);

        const fictitiousEvent = ControlsProxy.copyProperties(event, mouseEventProperties)
        this.coreThread.handlePointerUp(fictitiousEvent)
    }

    /**
     * @param {WheelEvent} event
     * @return {void} void
     */
    handleWheelEvent = (event) => {

        const fictitiousEvent = ControlsProxy.copyProperties(event, wheelEventProperties)
        this.coreThread.handleWheelEvent(fictitiousEvent)
    }

    /**
     * @param {TouchEvent} event
     * @return {void} void
     */
    handleTouchEvent = (event) => {
        event.preventDefault()

        const fictitiousEvent = ControlsProxy.copyProperties(event, touchEventProperties)

        const touches = []
        for (const touch of event.touches) {
            touches.push(ControlsProxy.copyProperties(touch, touchProperties))
        }
        fictitiousEvent.touches = touches

        this.sendEventMessage(fictitiousEvent)
    }

    // /**
    //  * @override createInit
    //  * @param {OffscreenCanvas} offscreenCanvas
    //  * @return {void}
    //  */
    // createInit(offscreenCanvas) {
    //     super.createInit(offscreenCanvas)
    // }

    /**
     * @override configEventListener
     * @return {void} void
     */
    configEventListener() {
        // this.handleResize()
        // window.addEventListener('resize', this.handleResize, {capture: true, passive: false})
        this.htmlElement.addEventListener('pointerdown', this.handlePointerDown, {capture: false, passive: true})
        this.htmlElement.addEventListener('wheel', this.handleWheelEvent)
        // this.htmlElement.addEventListener('touchstart', this.handleTouchEvent, {capture: true, passive: false})
        // this.htmlElement.addEventListener('touchmove', this.handleTouchEvent, {capture: true, passive: false})
        // this.htmlElement.addEventListener('touchend', this.handleTouchEvent, {capture: true, passive: false})
    }

    /**
     * @override dispose()
     * @return {void} void
     */
    dispose() {

        super.dispose()

        window.removeEventListener('resize', this.handleResize)
        this.htmlElement.removeEventListener('pointerdown', this.handlePointerDown)
        this.htmlElement.ownerDocument.removeEventListener('pointermove', this.handlePointerMove)
        this.htmlElement.ownerDocument.removeEventListener('pointerup', this.handlePointerUp)
        this.htmlElement.removeEventListener('wheel', this.handleWheelEvent)
        // this.htmlElement.removeEventListener('keydown', this.handleKeyboardEvent)
        // this.htmlElement.removeEventListener('keyup', this.handleKeyboardEvent)
        this.htmlElement.removeEventListener('touchstart', this.handleTouchEvent)
        this.htmlElement.removeEventListener('touchmove', this.handleTouchEvent)
        this.htmlElement.removeEventListener('touchend', this.handleTouchEvent)
    }
}

export default OrbitControlsProxy
