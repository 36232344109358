import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface initialStateInterface {
  helperData: { text: string, type: number }
  percent: number,
  loadedWorld: boolean,
}

export const initialState: initialStateInterface = {
  helperData: { text: 'Enter 3D mode *click', type: 0 },
  percent: 0,
  loadedWorld: false,
}


export const slice = createSlice({
  name: 'three',
  initialState,
  reducers: {
    SET_LOADED_PERCENT: (state, { payload }: PayloadAction<number>) => {
      state.percent = payload
    },
    SET_LOADED_WORLD: (state, { payload }: PayloadAction<boolean>) => {
      state.loadedWorld = payload
    },
    SET_HELP_DATA: (state, { payload }: PayloadAction<{type: number, text: string}>) => {
      state.helperData = payload
    },
  },
})

export const {
  SET_LOADED_PERCENT,
  SET_HELP_DATA,
  SET_LOADED_WORLD,
} = slice.actions

export default slice.reducer
